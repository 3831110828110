import React from 'react'

import { mergeProps } from '@react-aria/utils'
import { useListBox } from '@react-aria/listbox'
import { useOverlay, DismissButton } from '@react-aria/overlays'

import { s, styled } from '@horizon/styled'
import { prop } from '@solta/ramda-extra'

import { Option } from './Option'

const Menu = styled.div(
  s('border-solid border-1 border-grey-400 absolute bg-white w-full mt-5 py-4 z-1', {
    boxSizing: 'border-box',
    borderRadius: 10,
    borderTopRightRadius: 0,
    listStyle: 'none',
    overflow: 'hidden',
    right: 0,
  }),
  prop('menuStyles')
)

const ListBoxPopup = (props) => {
  const {
    menuStyles,
    popoverRef,
    listBoxRef,
    state,
    shouldUseVirtualFocus,
    ...otherProps
  } = props

  const { listBoxProps } = useListBox(
    {
      autoFocus: state.focusStrategy,
      disallowEmptySelection: true,
      shouldUseVirtualFocus,
      ...otherProps,
    },
    state,
    listBoxRef
  )

  const { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    popoverRef
  )

  return (
    <div {...overlayProps} ref={popoverRef}>
      <Menu
        {...mergeProps(listBoxProps, otherProps)}
        menuStyles={menuStyles}
        ref={listBoxRef}
      >
        {[...state.collection].slice(0, 6).map((item) => (
          <Option shouldUseVirtualFocus key={item.key} item={item} state={state} />
        ))}
      </Menu>
      <DismissButton onDismiss={() => state.close()} />
    </div>
  )
}

export { ListBoxPopup }
