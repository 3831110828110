import React from 'react'
import { s, styled } from '@horizon/styled'
import { useOption } from '@react-aria/listbox'
import { joinAddress } from './helpers/joinAddress'

const Item = styled.li(s('text-grey-800 text-base font-medium py-3'), {
  paddingLeft: 18,
  paddingRight: 18,
  cursor: 'pointer',
  '&:hover': s('bg-green-500'),
})
const Container = styled.div(s('flex'))
const AvatarContainer = styled.div(s('min-w-2'))
const Avatar = styled.div(
  s('flex justify-center items-center border-1 border-solid border-grey-50'),
  s('rounded-full text-grey-50 bg-green-700'),
  {
    width: 24,
    height: 24,
  }
)
const TextColumn = styled.div(s('flex flex-column w-full'))
const TopRow = styled.div(
  s(' flex text-base font-normal text-grey-500', {
    height: 21,
    lineHeight: 1.71,
    marginBottom: 2,
  })
)
const BottomRow = styled.div(
  s('inline-block text-sm font-medium text-grey-500', {
    lineHeight: 1.5,
    height: 24,
    width: 'calc(100% - 50px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
)

const Option = ({ item, state, shouldUseVirtualFocus }) => {
  const ref = React.useRef()
  const isDisabled = state.disabledKeys.has(item.key)
  const isSelected = state.selectionManager.isSelected(item.key)
  const isFocused = state.selectionManager.focusedKey === item.key

  const { value } = item
  const { inputValue } = state
  const formattedAddress = joinAddress(value.addresses)

  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      shouldUseVirtualFocus,
    },
    state,
    ref
  )

  const name = decodeURIComponent(`${value.firstName}&nbsp;${value.lastName}`)

  const styleMatchedName = (query, text) => {
    const regEx = new RegExp(`(${query})`, 'gi')
    const newName = text.replace(
      regEx,
      `<span style="color: ${s('text-grey-800').color}">$1</span>`
    )
    return { __html: newName }
  }

  const styleMatchedEmail = (query, email, formattedAddress) => {
    const regEx = new RegExp(`(${query})`, 'gi')
    const newEmail = email.replace(
      regEx,
      `<span style="color: ${s('text-grey-800').color}">$1</span>`
    )

    const dot = `
    <div style="display: inline-block; height: 2px; width: 2px; margin: auto 6px auto 6px;
    border-radius: 50%; background-color: ${s('bg-grey-500').backgroundColor};"></div>
    `

    const html = `
      <span>${newEmail} ${formattedAddress && dot} ${formattedAddress}</span>
  `

    return { __html: html }
  }

  return (
    <Item
      {...optionProps}
      ref={ref}
      isFocused={isFocused}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      <Container>
        <AvatarContainer>
          <Avatar>
            <div style={{ transform: 'translateY(-1px)' }}>
              {value.firstName[0].toUpperCase()}
            </div>
          </Avatar>
        </AvatarContainer>

        <TextColumn>
          <TopRow dangerouslySetInnerHTML={styleMatchedName(inputValue, name)} />
          <BottomRow
            dangerouslySetInnerHTML={styleMatchedEmail(
              inputValue,
              value.email,
              formattedAddress
            )}
          />
        </TextColumn>
      </Container>
    </Item>
  )
}

export { Option }
