import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlusIcon } from '@horizon/components/src/assets/images/plus.svg'
import { styled, s } from '@horizon/styled'
import { Button } from '@horizon/components'

const Label = styled.span(s('my-a ml-1'))
function InviteButton({ invitee, ...otherProps }) {
  return (
    <Button StartIcon={<PlusIcon style={s('w-1 h-1')} />} {...otherProps}>
      <Label>Invite new {invitee}</Label>
    </Button>
  )
}

InviteButton.propTypes = {
  invitee: PropTypes.string.isRequired,
}

export default InviteButton
