import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { FormInput } from '@horizon/components'

import { ReactComponent as OpenEyeIcon } from '@horizon/components/src/assets/images/openEye.svg'
import { ReactComponent as CloseEyeIcon } from '@horizon/components/src/assets/images/closeEye.svg'

const { string, bool, object } = PropTypes

function PasswordInput({
  id,
  name,
  description,
  disabled,
  inputProps,
  baseContainerStyle,
  testId,
  ...otherProps
}) {
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const Icon = showPassword ? CloseEyeIcon : OpenEyeIcon
  const type = showPassword ? 'text' : 'password'

  return (
    <FormInput
      id={id}
      name={name}
      label="Password"
      Icon={<Icon onClick={togglePasswordVisibility} />}
      type={type}
      disabled={disabled}
      description={description}
      inputProps={inputProps}
      baseContainerStyle={baseContainerStyle}
      testId={testId}
      {...otherProps}
    />
  )
}

PasswordInput.propTypes = {
  id: string,
  name: string,
  label: string,
  description: string,
  disabled: bool,
  inputProps: object,
  baseContainerStyle: object,
}

export { PasswordInput }
