import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '@solta/ramda-extra'
import ReactModal from 'react-modal'
import { styled, s } from '@horizon/styled'

const ModalBase = styled(ReactModal)({ overflow: 'visible' })

function Modal({
  isOpen = false,
  onClose = noop,
  overlayStyle,
  contentStyle,
  children,
}) {
  React.useEffect(() => {
    if (document.querySelector('body')) ReactModal.setAppElement('body')
  })

  const closeModal = () => {
    onClose(false)
  }

  return (
    <ModalBase
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          ...overlayStyle,
        }),
        content: s('p-0 bg-transparent border-0', {
          outline: 'none',
          inset: 'unset',
          top: '25%',
          ...contentStyle,
        }),
      }}
    >
      {children}
    </ModalBase>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  overlayStyle: PropTypes.object,
  contentStyle: PropTypes.object,
}

export default Modal
