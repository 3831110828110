import React from 'react'
import { styled, s } from '@horizon/styled'
import { Button as ButtonBase } from '@horizon/components'

import { calcPageNumbers } from './calcPageNumbers'

import { ReactComponent as RightIcon } from '../../assets/images/chevron-right.svg'
import { ReactComponent as LeftIcon } from '../../assets/images/chevron-left.svg'

const Container = styled.div(s('justify-end flex m-4 mr-0 items-center'))
const Button = styled(ButtonBase)(
  s('rounded-md rounded-tr-none ml-2 p-2'),
  s('text-grey-800 font-semibold'),
  { width: 28, height: 28, outline: 'none', lineHeight: 1.14 },
  ({ disabled }) => (disabled ? { pointerEvents: 'none' } : {})
)
const Text = styled.p(
  s('text-sm m-0 text-green-700 font-semibold', {
    paddingBottom: 2,
  }),
  ({ disabled }) => (disabled ? s('text-grey-400') : {}),
  ({ twoDigits }) => (twoDigits ? { paddingRight: 2 } : {})
)

const StyledLeftIcon = styled(LeftIcon)(
  { path: { stroke: '#2F8982' } },
  ({ disabled }) => (disabled ? { path: { stroke: s('text-grey-300').color } } : {})
)
const StyledRightIcon = styled(RightIcon)(
  { path: { stroke: '#2F8982' } },
  ({ disabled }) => (disabled ? { path: { stroke: s('text-grey-300').color } } : {})
)

export function Pagination({
  gotoPage,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  pageCount,
  pageIndex,
}) {
  const pageNumberList = calcPageNumbers(pageCount, pageIndex)

  return (
    <Container>
      <Button
        variant="text"
        onClick={previousPage}
        disabled={!canPreviousPage}
        style={s('w-4 p-0 pr-2')}
        containerStyle={{ ':disabled': s('bg-transparent') }}
        StartIcon={
          <StyledLeftIcon
            style={{
              width: 24,
              height: 28,
            }}
            disabled={!canPreviousPage}
          />
        }
      >
        <Text disabled={!canPreviousPage}>Back</Text>
      </Button>

      {pageNumberList.map((pageNumber, index, allNumbers) => {
        const isCurrentPage = pageNumber === pageIndex + 1

        const onClick = () => {
          const isNumber = pageNumber !== '...'
          if (isNumber) return gotoPage(pageNumber - 1)

          const isLeftEllipsis = index === 1
          if (isLeftEllipsis) return gotoPage(allNumbers[index + 1] - 2)

          return gotoPage(allNumbers[index - 1])
        }

        return (
          <Button
            key={index}
            variant={isCurrentPage ? 'contained' : 'text'}
            onClick={onClick}
          >
            <Text twoDigits={pageNumber >= 10}>{pageNumber}</Text>
          </Button>
        )
      })}

      <Button
        variant="text"
        onClick={nextPage}
        disabled={!canNextPage}
        style={s('w-4 p-0')}
        containerStyle={{ ':disabled': s('bg-transparent') }}
        EndIcon={
          <StyledRightIcon
            style={{
              width: 24,
              height: 28,
            }}
            disabled={!canNextPage}
          />
        }
      >
        <Text disabled={!canNextPage}>Next</Text>
      </Button>
    </Container>
  )
}
