import React, { useState, useEffect } from 'react'
import { useFilter } from '@react-aria/i18n'
import { isNilOrEmpty, isNotNilOrEmpty, defaultTo } from '@solta/ramda-extra'
import { Item } from '@react-stately/collections'
import { useField, useFormikContext } from 'formik'

import { s } from '@horizon/styled'

import { ComboBox } from '@horizon/components'
import { COUNTRY_OPTIONS as optionList } from './selectFieldOptions'

import PropTypes from 'prop-types'

const { string, bool, object } = PropTypes

function CountryComboBoxField({
  name,
  disabled = false,
  readOnly = false,
  optionStyles = s('p-1 pl-4'),
  testId,
  menuId,
  ...props
}) {
  const [{ value }, { touched, error }] = useField({ name })

  const { setFieldValue } = useFormikContext()

  const [fieldState, setFieldState] = useState(() => {
    const initialSelectedItem = isNotNilOrEmpty(value)
      ? optionList.find((option) => option?.value === value)
      : value

    return {
      isOpen: false,
      selectedKey: defaultTo('', value),
      inputValue: isNotNilOrEmpty(value)
        ? `${initialSelectedItem?.flag} ${initialSelectedItem?.label}`
        : '',
      items: optionList,
      currentValue: value,
    }
  })

  const { startsWith } = useFilter({ sensitivity: 'base' })

  const handleSelectionChange = (key) => {
    setFieldState((prevState) => {
      const selectedItem = prevState.items.find((option) => option?.value === key)

      return {
        isOpen: false,
        inputValue: isNilOrEmpty(selectedItem?.label)
          ? ''
          : `${selectedItem?.flag} ${selectedItem?.label}`,
        selectedKey: key,
        items: optionList.filter((item) =>
          startsWith(item.label, selectedItem?.label ?? '')
        ),
        currentValue: selectedItem?.value,
      }
    })
  }

  const handleInputChange = (value) => {
    setFieldState(() => {
      return {
        isOpen: true,
        inputValue: value,
        selectedKey: null,
        items: optionList.filter((item) => startsWith(item?.label, value)),
        currentValue: undefined,
      }
    })
  }

  const handleOpenChange = (isOpen) => {
    setFieldState((prevState) => ({
      isOpen,
      inputValue: prevState.inputValue,
      selectedKey: prevState.selectedKey,
      items: prevState.items,
      currentValue: prevState.currentValue,
    }))
  }

  useEffect(() => {
    setFieldValue(name, fieldState.currentValue)
  }, [fieldState.currentValue, name, setFieldValue])

  return (
    <ComboBox
      items={fieldState.items}
      inputValue={fieldState.inputValue}
      isOpen={fieldState.isOpen && fieldState.items.length > 0}
      selectedKey={fieldState.selectedKey}
      onSelectionChange={handleSelectionChange}
      onInputChange={handleInputChange}
      onOpenChange={handleOpenChange}
      disabled={disabled}
      readOnly={readOnly}
      touched={touched}
      error={error}
      menuTrigger="focus"
      optionStyles={optionStyles}
      testId={testId}
      menuId={menuId}
      name={name}
      {...props}
    >
      {(item) => (
        <Item key={item.value} textValue={item.label}>
          {item.flag}&nbsp;{item.label}
        </Item>
      )}
    </ComboBox>
  )
}

CountryComboBoxField.propTypes = {
  name: string.isRequired,
  label: string,
  'aria-label': string,
  placeholder: string,
  disabled: bool,
  readOnly: bool,
  listBoxStyles: object,
  optionStyles: object,
}

export { CountryComboBoxField }
