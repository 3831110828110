import React from 'react'
import { styled, s } from '@horizon/styled'

import NeoLogo from '@horizon/components/src/assets/images/neo-logo.png'

const Container = styled.div(
  s('relative flex flex-column pr-12 pt-16', {
    width: '596px',
    flexShrink: 0,
    paddingLeft: 80,
  })
)
const Title = styled.h2(s('text-5xl font-semibold text-green-900 m-0'))

const WelcomeSection = () => (
  <Container>
    <img src={NeoLogo} width={128} height={47} style={s('mb-24')} />
    <Title>Welcome to Neo Mortgage</Title>
  </Container>
)

export { WelcomeSection }
