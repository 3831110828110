import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '../TextField'

import { ReactComponent as OpenEyeIcon } from '@horizon/components/src/assets/images/openEye.svg'
import { ReactComponent as CloseEyeIcon } from '@horizon/components/src/assets/images/closeEye.svg'

const { string, bool, object } = PropTypes

function PasswordField({
  id,
  name,
  description,
  disabled,
  inputProps,
  baseContainerStyle,
  testId,
  ...otherProps
}) {
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const Icon = showPassword ? CloseEyeIcon : OpenEyeIcon
  const type = showPassword ? 'text' : 'password'

  return (
    <TextField
      id={id}
      name={name}
      label="Password"
      SuffixIcon={<Icon onClick={togglePasswordVisibility} />}
      inputBaseProps={{
        type,
        disabled,
        description,
        ...inputProps,
      }}
      containerStyle={baseContainerStyle}
      testId={testId}
      fast={false}
      {...otherProps}
    />
  )
}

PasswordField.propTypes = {
  id: string,
  name: string,
  label: string,
  description: string,
  disabled: bool,
  inputProps: object,
  baseContainerStyle: object,
}

export { PasswordField }
