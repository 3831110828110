import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { normalizeError, ProfileService } from '@horizon/services'
import { prop, path, isNilOrEmpty, head } from '@solta/ramda-extra'
import { thunkErrorProcessor } from '@horizon/error-standardizer'

const profileApiUri = process.env.REACT_APP_PROFILE_API_URI

const profileService = new ProfileService({ baseUri: profileApiUri })

/**
 * @typedef {import('index').Profile} Profile
 */

/**
 * @type {import('index').fetchMyProfile}
 */
export const fetchMyProfile = createAsyncThunk(
  'profile/fetchMyProfile',
  async (_, { rejectWithValue }) => {
    try {
      const profile = await profileService.readMyProfile()

      return profile
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

/**
 * @type {import('index').updateProfile}
 */
export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (updatingProps, { rejectWithValue, getState }) => {
    const { id } = getState().profile

    try {
      await profileService.updateMyProfile(id, updatingProps)

      return updatingProps
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)
/**
 * @type {Profile}
 */
const initialState = {
  id: '',
  authId: '',
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  gender: '',
  roles: [],
  status: 'inactive',
  suspended: false,
  dateOfBirth: '',
  userCurrentRole: undefined,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearProfile: () => {
      return initialState
    },
    setUserCurrentRole: (state, action) => {
      state.userCurrentRole = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMyProfile.fulfilled, (_, action) => {
        return action.payload
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        return { ...state, ...payload }
      })
  },
})

const { reducer: profileReducer, actions } = profileSlice

const selectFetchMyProfileStatus = path(['asyncState', 'profile/fetchMyProfile'])

/**
 * @param {import('index').WithProfileState} state
 * @returns {Profile}
 */
const selectProfile = (state) => state.profile

const selectIsFetchMyProfileLoading = createSelector(
  selectFetchMyProfileStatus,
  (status) => isNilOrEmpty(status) || status?.status === 'pending'
)

/**
 * @param {Profile} profile
 */
const isAuthorized = (profile) => !isNilOrEmpty(profile) && !profile.suspended

/**
 * @param {Profile} profile
 */
const isAuthenticated = (profile) =>
  !isNilOrEmpty(profile) && !isNilOrEmpty(profile.authId)

const selectIsSessionValid = createSelector(
  selectProfile,
  /**
   * @param {Profile} profile
   */
  (profile) => isAuthenticated(profile) && isAuthorized(profile)
)
const selectProfileRoles = createSelector(selectProfile, prop('roles'))

const selectDefaultRole = createSelector(selectProfileRoles, (roles) => head(roles))

const selectProfileId = createSelector(selectProfile, prop('id'))

const selectUserName = createSelector(selectProfile, prop('firstName'))

const selectUserCurrentRole = createSelector(selectProfile, prop('userCurrentRole'))

const formattedProfile = createSelector(selectProfile, (profile) => {
  return {
    ...profile,
    email: profile?.email ?? '-',
    gender: profile?.gender ?? '-',
    dateOfBirth: profile?.dateOfBirth ?? 'DD/MM/YYYY',
  }
})

export const { clearProfile, setUserCurrentRole } = actions

export {
  profileReducer,
  selectProfile,
  selectIsFetchMyProfileLoading,
  selectProfileRoles,
  selectProfileId,
  selectIsSessionValid,
  selectDefaultRole,
  selectUserName,
  selectUserCurrentRole,
  formattedProfile,
}
