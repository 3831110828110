import ky from 'ky'
import { prop, defaultTo, isNotNilOrEmpty, isNilOrEmpty } from '@solta/ramda-extra'
import Cookies from 'js-cookie'
import moment from 'moment'
import { createCustomError } from '@horizon/error-standardizer'

import { refreshAuthSession } from './utils'
import { AUTH, APP_URLS } from '@horizon/constants'

const { AUTH_COOKIE_KEY, AUTH_ID_TOKEN_KEY } = AUTH

const getUserCurrentRole = () => window.localStorage.getItem('vegaUserCurrentRole')

const getIdToken = () => {
  const parsedToken = JSON.parse(defaultTo(null, Cookies.get(AUTH_ID_TOKEN_KEY)))
  return isNotNilOrEmpty(parsedToken) ? parsedToken?.idToken : null
}

/**
 * @type {import('index').ClientFactory}
 */
const getPublicHttpClient = (options) =>
  ky.create({
    prefixUrl: options.baseUri,
    headers: {
      'content-type': 'application/json',
    },
  })

/**
 * @type {import('index').ClientFactory}
 */
const getHttpClient = (options) =>
  ky.create({
    prefixUrl: options.baseUri,
    headers: {
      'content-type': 'application/json',
    },
    hooks: {
      beforeRequest: [
        async (req) => {
          let authSession = JSON.parse(defaultTo(null, Cookies.get(AUTH_COOKIE_KEY)))

          const expiryDate = prop('expiryDate', authSession)

          const hasAuthSessionExpired = moment().isAfter(moment.unix(expiryDate))

          if (isNilOrEmpty(expiryDate) || hasAuthSessionExpired) {
            await refreshAuthSession()
            authSession = JSON.parse(defaultTo(null, Cookies.get(AUTH_COOKIE_KEY)))
          }

          const userHasNotLoggedIn = isNilOrEmpty(authSession)
          if (userHasNotLoggedIn) {
            const appInfo = JSON.parse(defaultTo(null, Cookies.get('appInfo')))
            const currentAppName = appInfo?.name

            if (isNilOrEmpty(currentAppName)) {
              window.location.replace(`${APP_URLS.AUTH}`)
            } else {
              window.location.replace(
                `${APP_URLS.AUTH}sign-in?redirectApp=${currentAppName}`
              )
            }

            throw createCustomError({
              type: 'userIsNotLoggedIn',
              description: 'User is not logged in',
            })
          }

          const token = prop('accessToken', authSession)

          req.headers.set('Authorization', `Bearer ${token}`)
          req.headers.set('vega-id-token', getIdToken())
          req.headers.set('vega-user-current-role', getUserCurrentRole())

          return req
        },
      ],
    },
  })

export { getHttpClient, getPublicHttpClient }
