import { s } from '@horizon/styled/v2'

export const customStyles = {
  containerStyles: s(
    'bg-primary-flat-white content-center items center justify-center text-center'
  ),
  headingStyles: s('text-primary', { fontFamily: 'sohne' }),
  innerLinkStyles: s('text-primary'),
  inputStyles: {
    '&:hover:not([disabled])': s('border-1 border-primary', { outline: 'none' }),
    '&:focus': s('border-1 border-primary', {
      outline: '2px solid',
      outlineColor: s('text-primary').color,
    }),
    '&:active': s('border-1 border-primary', { outline: 'none' }),
  },
  helperTextStyles: s('text-grey-600'),
  buttonStyles: s('shadow-none bg-secondary text-primary', {
    '&:hover:not([disabled])': s('bg-secondary', {
      boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    }),

    '&:disabled': s('bg-grey-200 text-grey-600'),
  }),
}
