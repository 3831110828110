import { filter, map, isNilOrEmpty } from '@solta/ramda-extra'
import { APP_SERVICES } from '@horizon/constants'
import { USER_ROLES } from '@vega/constants'

const {
  SUPER_ADMIN,
  LEAD_ASSESSOR,
  CREDIT_ASSESSOR,
  // LENDING_ADMIN,
  // BROKER,
  // CLIENT,
  // CREDIT_ASSESSOR,
  // BANKING_ADMIN,
  // BANKING_CLIENT,
  // BANKING_PORTFOLIO_ADMIN,
} = USER_ROLES

const {
  VEGA_LOAN_ORIGINATION,
  // VEGA_LEND_ADMIN,
  // VEGA_LEND_BROKER,
  // VEGA_LEND_CLIENT,
  // VEGA_BANKING_ADMIN,
  // VEGA_BANKING_CUSTOMER,
  // VEGA_PORTFOLIO_MANAGEMENT,
} = APP_SERVICES

const appServiceACL = [
  // {
  //   role: LENDING_ADMIN,
  //   app: VEGA_LEND_ADMIN,
  // },
  // {
  //   role: BROKER,
  //   app: VEGA_LEND_BROKER,
  // },
  // {
  //   role: CLIENT,
  //   app: VEGA_LEND_CLIENT,
  // },
  {
    role: CREDIT_ASSESSOR || LEAD_ASSESSOR,
    app: VEGA_LOAN_ORIGINATION,
  },
  // {
  //   role: BANKING_CLIENT,
  //   app: VEGA_BANKING_CUSTOMER,
  // },
  // {
  //   role: BANKING_ADMIN,
  //   app: VEGA_BANKING_ADMIN,
  // },
  // {
  //   role: BANKING_PORTFOLIO_ADMIN,
  //   app: VEGA_PORTFOLIO_MANAGEMENT,
  // },
]

const getAuthorisedAppKeys = map((acl) => acl.app)

const getAllAppsKeys = () => getAuthorisedAppKeys(appServiceACL)

export default (usersRoles) => {
  if (isNilOrEmpty(usersRoles)) return []

  if (usersRoles.includes(SUPER_ADMIN)) return getAllAppsKeys()

  const filterUnauthorisedApps = (ac) => usersRoles.includes(ac.role)
  const authorisedApps = filter(filterUnauthorisedApps, appServiceACL)

  return getAuthorisedAppKeys(authorisedApps)
}
