import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { helpers } from '@vega/constants'
import { pipe } from '@solta/ramda-extra'
import { TOTPSetup as Setup } from '@midas/idkit-ui-web'
import { s, styled } from '@horizon/styled/v2'
import { fetchMyProfile, selectProfile } from '@horizon/redux.profile'
import logoIcon from '@horizon/components/src/assets/images/signInLogo.svg'
import { customStyles } from 'utils/customStyles'

const Container = styled.div(s('h-full bg-primary-flat-white flex justify-center'))

const redirectByDefaultRole = (role) => {
  const openApp = (url) => window.location.replace(url)
  pipe(helpers.lookUpAppRouteByRole, openApp)(role)
}

export const TOTPSetup = () => {
  const [user, setUser] = useState({})
  const [isSetupComplete, setIsSetupComplete] = useState(false)

  const dispatch = useDispatch()
  const profile = useSelector(selectProfile)

  if (isSetupComplete) {
    const role = profile.roles[0]
    redirectByDefaultRole(role)
  }

  useEffect(() => {
    dispatch(fetchMyProfile())
  }, [dispatch])

  return (
    <Container>
      <Setup
        Logo={() => <img src={logoIcon} width="60%" />}
        customStyles={customStyles}
        onSuccess={(user) => {
          setUser(user)
          setIsSetupComplete(true)
        }}
      />
    </Container>
  )
}
