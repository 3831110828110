import React from 'react'
import { styled, s } from '@horizon/styled'
import { WelcomeSection } from './WelcomeSection'
import { CreateAccountSection } from './CreateAccountSection'
import {} from '.'
import { ReactComponent as KeepingInTouch } from '@horizon/components/src/assets/images/keeping-in-touch.svg'
import { ReactComponent as WhiteCircle } from '@horizon/components/src/assets/images/white-circle.svg'

const Root = styled.div(
  s('relative flex', {
    minHeight: '900px',
  })
)
const Gradient = styled.div({
  borderTopRightRadius: '40px',
  borderBottomRightRadius: '40px',
  width: '596px',
  backgroundImage: 'linear-gradient(#7235E4, #151683)',
})
const CircleImage = styled(WhiteCircle)(s('absolute pin-bl-0'))
const KeepingInTouchImage = styled(KeepingInTouch)(s('absolute pin-bl-0'))

const CreateClientAccount = () => {
  return (
    <Root>
      <Gradient>
        <WelcomeSection />
      </Gradient>
      <CreateAccountSection />
      <CircleImage />
      <KeepingInTouchImage />
    </Root>
  )
}

export { CreateClientAccount }
