import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AuthSdk as Wallet } from '@midas/idkit'
import Cookies from 'js-cookie'
import { isDev } from 'utils/isDev'

import { clearProfile } from '@horizon/redux.profile'
import { AUTH } from '@horizon/constants'

const VEGA_SUBDOMAIN = process.env.REACT_APP_SUB_DOMAIN

function SignOut() {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    Wallet.signOut()
    Cookies.set(AUTH.AUTH_COOKIE_KEY, null, {
      domain: VEGA_SUBDOMAIN,
      secure: !isDev(),
      sameSite: 'strict',
    })

    Cookies.set(AUTH.AUTH_ID_TOKEN_KEY, null, {
      domain: VEGA_SUBDOMAIN,
      secure: !isDev(),
      sameSite: 'strict',
    })

    dispatch(clearProfile())
    history.replace('sign-in')
  }, [dispatch, history])
  return null
}

export { SignOut }
