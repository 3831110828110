import { useState } from 'react'
import { isEmpty } from '@solta/ramda-extra'
// import { loanApplicationService } from '@horizon/lending/src/apiService' // Fix this incorrect dependency direction
import { useDebouncedCallback } from 'use-debounce'

const useSearchApplicant = (delay = 1000) => {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState([])

  const applicantSearch = useDebouncedCallback(async (query) => {
    if (isEmpty(query)) {
      setResults([])
      return
    }

    setIsLoading(true)

    try {
      const applicants = []
      // const applicants = await loanApplicationService.searchApplicants(query) // TODO: fix this!
      setResults(applicants)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, delay)

  const resetResults = () => setResults([])
  return {
    isLoading,
    results,
    applicantSearch,
    resetResults,
  }
}

export { useSearchApplicant }
