import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import { ThemeProvider, breakpoints } from '@horizon/styled'
import { AuthSdk as Wallet } from '@midas/idkit'
import Router from './router'
import store from './app/store'

const region = process.env.REACT_APP_AUTH_REGION
const userPoolId = process.env.REACT_APP_AUTH_USER_POOL_ID
const userPoolWebClientId = process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID
const identityPoolId = process.env.REACT_APP_AUTH_IDENTITY_POOL_ID

Wallet.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    identityPoolId,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={{ breakpoints }}>
        <Router />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
