import React from 'react'

import { styled, s } from '@horizon/styled'

const Container = styled.div(
  s('flex flex-column p-9 bg-white mt-5 show-box border-grey-300', {
    borderRadius: '12px 0 12px 12px',
    '& > div:not(:last-child)': s('mb-5'),
  })
)

function Paper({ children }) {
  return <Container>{children}</Container>
}

export default Paper
