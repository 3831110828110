import React from 'react'

import PropTypes from 'prop-types'
import { styled, s } from '@horizon/styled'

const { object, number, func, bool } = PropTypes

const Container = styled.div(
  s('flex items-center justify-center bg-green-50', {
    borderRadius: '6px 0 6px 6px',
    cursor: 'pointer',
  }),
  ({ size }) => ({ width: size, height: size }),
  ({ active }) =>
    active && s('border-green-400 border-solid shadow-md', { borderWidth: 1 })
)

function Switch({ size = 44, Icon, active, onClick, ...props }) {
  return (
    <Container size={size} active={active} onClick={onClick} {...props}>
      {Icon}
    </Container>
  )
}

Switch.propTypes = {
  size: number,
  active: bool,
  Icon: object.isRequired,
  onClick: func.isRequired,
}

export default Switch
