import React from 'react'
import { useRadio } from '@react-aria/radio'
import { useRadioContext } from './LoanFlexibilityPreferenceField'
import { styled, s } from '@horizon/styled'

const Label = styled.label(
  s('flex h-2 w-2 justify-center items-center'),
  s('border-solid border-grey-300 bg-transparent border-1'),
  s('text-center font-semibold text-sm text-grey-500', {
    lineHeight: 1.3,
    cursor: 'pointer',
    borderRadius: '50%',
  }),
  ({ isSelected }) => isSelected && s('bg-green-700 border-green-700 text-white')
)

const Input = styled.input(
  s('w-0 fixed', {
    opacity: 0,
    cursor: 'pointer',
  })
)

const FlexibilityLevel = (props) => {
  const { children } = props
  const state = useRadioContext()
  const ref = React.useRef(null)
  const { inputProps } = useRadio(props, state, ref)

  return (
    <Label isSelected={inputProps.checked}>
      <Input ref={ref} {...inputProps} />
      {children}
    </Label>
  )
}

export default FlexibilityLevel
