import React from 'react'
import { styled, s } from '@horizon/styled'

import NeoLogo from '@horizon/components/src/assets/images/neo-logo.png'

const Container = styled.div(
  s('relative flex flex-column pl-32 pr-24 pt-16', {
    width: '580px',
    flexShrink: 0,
  })
)

const Title = styled.h2(s('text-5xl font-semibold text-white m-0'))
const Description = styled.p(s('text-2xl text-grey-300'))

const ImportantText = styled.span(s('text-white'))

// TODO: Replace Neo logo with white variant when it is done
function WelcomeSection() {
  return (
    <Container>
      <img src={NeoLogo} width={128} height={47} style={s('mb-24')} />
      <Title>Welcome to Neo Lend</Title>
      <Description>
        Pre-approve your clients in under <ImportantText>30</ImportantText> minutes for
        a <ImportantText>Neo Private</ImportantText> home loan
      </Description>
    </Container>
  )
}

export { WelcomeSection }
