import React from 'react'

import { styled, s } from '@horizon/styled'
import { prop } from '@solta/ramda-extra'
import { ReactComponent as Search } from '@horizon/components/src/assets/images/search.svg'

const Container = styled.div(
  s('flex relative items-center rounded-lg rounded-tr-none bg-green-50', {
    border: '1px solid #f1f5f9',
  }),
  prop('containerStyle')
)
const SearchBarContainer = styled.div(s('flex items-center w-full'))
const SearchIcon = styled(Search)(s('absolute ml-4'))

const Input = styled.input(
  s('pl-12 pr-4 pt-3 pb-3 w-full border-0 bg-transparent text-grey-500 text-base', {
    '&:focus': {
      outline: 'none',
    },
  })
)

function SearchBar({
  onInputChange,
  placeholder,
  disabled,
  value,
  children,
  containerStyle,
  testId,
  ...inputProps
}) {
  return (
    <Container containerStyle={containerStyle}>
      <SearchBarContainer>
        <SearchIcon height={20} width={20} />
        <Input
          placeholder={placeholder}
          onChange={onInputChange}
          value={value}
          type="text"
          disabled={disabled}
          data-test-id={testId}
          {...inputProps}
        />
      </SearchBarContainer>
      {children}
    </Container>
  )
}

export default SearchBar
