import React from 'react'
import { useOption } from '@react-aria/listbox'
import { styled, s } from '@horizon/styled/v2'

const Container = styled.li(
  s('px-3 py-2 text-sm text-grey-800', { cursor: 'pointer', outline: 'none' }),
  ({ isDisabled }) => ({
    ...(isDisabled && s('text-grey-400', { cursor: 'initial' })),
  }),
  { '&:hover, &:focus': s('bg-grey-100') }
)

function Option({ item, state }) {
  const ref = React.useRef()
  const isDisabled = state.disabledKeys.has(item.key)
  const isSelected = state.selectionManager.isSelected(item.key)
  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
    },
    state,
    ref
  )

  return (
    <Container {...optionProps} ref={ref} isDisabled={isDisabled}>
      {item.rendered}
    </Container>
  )
}

export { Option }
