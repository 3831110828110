import React from 'react'
import { styled, s } from '@horizon/styled'

const Container = styled.div(
  s('absolute pin-0 w-full h-full bg-grey-900', { opacity: 0.45 })
)

export const Overlay = ({ ...otherProps }) => {
  return <Container {...otherProps}>Overlay</Container>
}
