import React from 'react'
import { styled, s, Col, Row } from '@horizon/styled'
import { Modal, Button } from '@horizon/components'

const Container = styled.div(
  s('p-6 bg-white', {
    borderRadius: 12,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    maxWidth: 490,
  })
)
const Message = styled.div(s('text-center text-grey-600 text-sm mb-4'))
const ButtonContainer = styled.div(s('flex justify-center'))

function ForceSignedOutModal({ isOpen, onAcknowledgement }) {
  return (
    <Modal isOpen={isOpen}>
      <Container>
        <Message>
          <span>
            Hello, you’ve been inactive for a while, we logged you out to protect your
            privacy.
          </span>
        </Message>
        <Row style={s('mt-9')}>
          <Col span={24} lg={24}>
            <ButtonContainer>
              <Button onClick={() => onAcknowledgement()} style={s('w-full')}>
                Okay, Thank You
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default ForceSignedOutModal
