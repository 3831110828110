import { useState, useCallback } from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { loanApplicationService } from '@horizon/components/apiService'
import { mapToAddressViewModel } from './mapToAddressViewModel'

const useIllionAddressSearch = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState([])

  const searchAddress = useCallback(async (query) => {
    if (isNilOrEmpty(query)) {
      setResults([])
      return
    }

    setIsLoading(true)
    try {
      const response = await loanApplicationService.getPropertyAddresses(query)

      setResults(mapToAddressViewModel(response))
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  const resetResults = () => {
    setResults([])
  }

  return {
    isLoading,
    results,
    searchAddress,
    resetResults,
  }
}

export { useIllionAddressSearch }
