/* eslint-disable complexity */
import React, { memo } from 'react'
import { Field as SlowField, FastField } from 'formik'
import { prop, mergeDeepRight, isNotNilOrEmpty, noop } from '@solta/ramda-extra'
import { useTextField } from 'react-aria'
import { PropTypes } from 'prop-types'
import { styled, s } from '@horizon/styled/v2'
import { FormMessage } from '../../FormInput'
import NumberFormat from 'react-number-format'

const { string, bool } = PropTypes
const Root = styled.div(
  s('border-0 w-full flex flex-column'),
  s('text-grey-800 text-base font-normal', {
    lineHeight: 1.5,
  }),
  prop('containerStyle')
)
const LabelContainer = styled.div(s('flex justify-between mb-1'))
const OptionalText = styled.span(s('flex items-center text-xs text-grey-500'))
const Label = styled.label(
  s('text-sm font-normal text-grey-800', {
    pointerEvents: 'none',
  })
)
const InputContainer = styled.div(
  s('rounded-lg border-1 border-solid border-grey-400 bg-transparent'),
  s('flex items-center justify-between p-4 w-full', { height: 52 }),
  {
    outline: 'none',
    ':hover': s('border-1 border-primary'),
    ':focus-within': s('border-1 border-primary', {
      boxShadow: `0 0 0 2pt ${s('text-primary').color}`,
    }),
    ':active': s('border-1 border-primary', { outline: 'none' }),
  },
  ({ isFilled }) => isFilled && s('border-grey-300'),
  ({ hasError }) =>
    hasError &&
    s('border-accent-red', {
      borderWidth: 1,
      outline: 'none',
      '&:hover, &:active, &:focus-within': s('border-accent-red', { outline: 'none' }),
    }),
  ({ isReadOnly }) =>
    isReadOnly && s('bg-grey-200 border-grey-400', { pointerEvents: 'none' }),
  ({ isDisabled }) =>
    isDisabled && s('bg-grey-200 border-grey-400', { pointerEvents: 'none' }),
  prop('inputContainerStyle')
)
const Prefix = styled.span(s('text-grey-900 text-base h-1 flex items-center'))
const Suffix = styled.span(s('text-grey-900 h-1 flex items-center'))
const Input = styled(NumberFormat)(
  s('h-full w-full border-0 bg-transparent p-0 m-0'),
  s('text-grey-900 text-base', {
    '::placeholder': s('text-grey-500'),
  }),
  {
    outline: 'none',
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-900'),
  ({ isDisabled }) => isDisabled && s('text-grey-900'),
  prop('inputStyle')
)
function NumberField({
  children,
  prefix,
  suffix,
  isOptional = false,
  fast = true,
  type = 'text',
  onChange = noop,
  containerStyle,
  placeholder = '',
  inputStyle,
  inputContainerStyle,
  format,
  thousandSeparator,
  testId,
  customHasError = (touched, error) => touched && error,
  ...props
}) {
  const { label, name, id, isDisabled, isReadOnly, onClick } = props

  const ref = React.useRef()
  const { labelProps, inputProps } = useTextField(props, ref)

  const Field = fast ? FastField : SlowField

  return (
    <Field name={name} id={id} type={type}>
      {({ field, meta }) => {
        const { value, onChange: onChangeFormik } = field
        const { touched, error } = meta

        const hasError = customHasError(touched, isNotNilOrEmpty(error))

        return (
          <Root containerStyle={containerStyle} {...props}>
            {isNotNilOrEmpty(label) && (
              <LabelContainer>
                <Label {...labelProps}>{label}</Label>
                {isOptional && <OptionalText>Optional</OptionalText>}
              </LabelContainer>
            )}

            <InputContainer
              isFilled={Boolean(value)}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              isTouched={touched}
              hasError={hasError}
              inputContainerStyle={inputContainerStyle}
            >
              {prefix && <Prefix>{prefix}</Prefix>}
              <Input
                ref={ref}
                data-test-id={testId}
                {...mergeDeepRight(inputProps, {
                  value,
                  onChange: (v) => {
                    onChangeFormik(v)
                    onChange(v)
                  },
                  onClick,
                })}
                inputStyle={inputStyle}
                isDisabled={isDisabled}
                isReadOnly={isReadOnly}
                thousandSeparator={thousandSeparator}
                format={format}
                placeholder={placeholder}
              />
              {suffix && <Suffix>{suffix}</Suffix>}
            </InputContainer>
            {children}
            <FormMessage message={error} visible={hasError} id={name} />
          </Root>
        )
      }}
    </Field>
  )
}

NumberField.propTypes = {
  prefix: string,
  suffix: string,
  isOptional: bool,
  type: string,
  fast: bool,
}
export default memo(NumberField)
