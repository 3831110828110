/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import { DottedCircleLoader } from '@horizon/components'
import { isNilOrEmpty, isNotNilOrEmpty, prop, pipe, reject } from '@solta/ramda-extra'
import { styled, s } from '@horizon/styled'
import { BarChartBase } from './BarChartBase'
import { EmptyBarChart } from './EmptyBarChart'
import ParentSize from '@visx/responsive/lib/components/ParentSize'

const { object, number, bool, func, string } = PropTypes

const Container = styled.div(
  s(''),
  ({ width, height }) => ({ width, height }),
  prop('containerStyle')
)

const LoaderContainer = styled.div(
  s('flex items-center justify-center'),
  ({ width, height }) => ({ width, height }),
  prop('containerStyle')
)

const removeItemWithZeroValue = reject((item) => item?.value === 0)

function BarChart({
  data,
  approxHighestValueForEmptyChart = 10,
  isLoading = false,
  LoaderComponent,
  containerStyle,
  ...otherProps
}) {
  const filteredChartData = pipe(removeItemWithZeroValue)(data)

  if (isLoading) {
    if (isNotNilOrEmpty(LoaderComponent)) return LoaderComponent

    return (
      <LoaderContainer containerStyle={containerStyle}>
        <DottedCircleLoader size="lg" style={{ transform: 'translateY(-20px)' }} />
      </LoaderContainer>
    )
  }

  if (isNilOrEmpty(filteredChartData)) {
    return (
      <Container containerStyle={containerStyle}>
        <ParentSize>
          {({ width: parentWidth, height: parentHeight }) => (
            <EmptyBarChart
              width={parentWidth}
              height={parentHeight}
              approxHighestValueForEmptyChart={approxHighestValueForEmptyChart}
              {...otherProps}
            />
          )}
        </ParentSize>
      </Container>
    )
  }

  return (
    <Container containerStyle={containerStyle}>
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) => (
          <BarChartBase
            data={filteredChartData}
            width={parentWidth}
            height={parentHeight}
            {...otherProps}
          />
        )}
      </ParentSize>
    </Container>
  )
}

BarChartBase.propTypes = {
  data: arrayOf(
    PropTypes.shape({
      label: string,
      value: number,
    })
  ).isRequired,
  containerStyle: object,
  isLoading: bool,
  approxHighestValueForEmptyChart: number,
  LoaderComponent: func,
}

export { BarChart }
