import React from 'react'
import { forEach, prop, and } from '@solta/ramda-extra'
import { s } from '@horizon/styled'
import { Pie } from '@visx/shape'
import PropTypes, { arrayOf } from 'prop-types'
import { Group } from '@visx/group'
import { scaleOrdinal } from '@visx/scale'
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip'
import { localPoint } from '@visx/event'

const { object, number, func, bool, string } = PropTypes

const pickPropFromObjectArray = (keyToBePicked, objArray = []) => {
  const newObjArray = []

  forEach((obj) => {
    const picked = prop(keyToBePicked, obj)
    newObjArray.push(picked)
  }, objArray)

  return newObjArray
}

const calculatePieShapeValues = (width, height, margin) => {
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const radius = Math.min(innerWidth, innerHeight) / 2

  const top = centerY + margin.top
  const left = centerX + margin.left

  const groupPositioning = { top, left }

  return { innerWidth, innerHeight, centerY, centerX, radius, groupPositioning }
}

const getArcColorByLabel = (data, arcColors) =>
  scaleOrdinal({
    domain: data.map((l) => l.label),
    range: arcColors,
  })

const frequencySelector = (d) => d.frequency
const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 }
const defaultPieLabelStyle = {
  fill: '#ffffff',
  fontSize: 22,
  textAnchor: 'middle',
  pointerEvents: 'none',
}

function PieChartBase({
  width,
  height,
  data = [],
  tooltipOffsetX = 0,
  tooltipOffsetY = 0,
  showPieLabel = true,
  shouldRenderBlankPie = true,
  setPieLabel = (label) => label,
  pieStyle,
  pieLabelStyle,
  tooltipContainerStyle,
  TooltipChildren,
  margin = defaultMargin,
}) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const arcColors = pickPropFromObjectArray('color', data)

  const handleMouseOverOnPieArc = (event, tooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event)
    if (coords !== null)
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData,
      })
  }

  const { centerY, centerX, radius, groupPositioning } = calculatePieShapeValues(
    width,
    height,
    margin
  )
  const { top, left } = groupPositioning

  const pieSortValues = (a, b) => b - a

  if (shouldRenderBlankPie)
    return (
      <div style={s('relative inline-block')}>
        <svg width={width} height={height} fill={s('bg-grey-300').backgroundColor}>
          <Group top={margin.top} left={margin.left}>
            <circle cx={centerX} cy={centerY} r={radius} />
          </Group>
        </svg>
      </div>
    )

  return (
    <div style={s('relative inline-block')}>
      <svg width={width} height={height}>
        <Group top={top} left={left} style={pieStyle}>
          <Pie
            data={data}
            pieValue={frequencySelector}
            pieSortValues={pieSortValues}
            outerRadius={radius}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { label, frequency } = arc.data
                const arcPath = pie.path(arc)
                const [arcCentroidX, arcCentroidY] = pie.path.centroid(arc)
                const arcFill = getArcColorByLabel(data, arcColors)(label)

                const pieLabel = setPieLabel(label) || label

                const hasSpaceForPieLabel = arc.endAngle - arc.startAngle >= 0.1

                return (
                  <g
                    key={`arc-${label}-${index}`}
                    onPointerMove={(event) =>
                      handleMouseOverOnPieArc(event, { label, frequency })
                    }
                    onPointerLeave={hideTooltip}
                  >
                    <path d={arcPath} fill={arcFill} />
                    {and(hasSpaceForPieLabel, showPieLabel) && (
                      <text
                        x={arcCentroidX}
                        y={arcCentroidY}
                        dy=".33em"
                        style={{ ...defaultPieLabelStyle, ...pieLabelStyle }}
                      >
                        {pieLabel}
                      </text>
                    )}
                  </g>
                )
              })
            }}
          </Pie>
        </Group>
      </svg>

      {tooltipOpen && (
        <TooltipWithBounds
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop + tooltipOffsetY}
          left={tooltipLeft + tooltipOffsetX}
          style={{ ...defaultStyles, ...tooltipContainerStyle, zIndex: 2 }}
        >
          {TooltipChildren ? (
            <TooltipChildren tooltipData={tooltipData} />
          ) : (
            <strong>{tooltipData?.label}</strong>
          )}
        </TooltipWithBounds>
      )}
    </div>
  )
}

PieChartBase.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  data: arrayOf(
    PropTypes.shape({
      label: string,
      frequency: number,
      color: string,
    })
  ).isRequired,
  margin: PropTypes.shape({
    top: number,
    right: number,
    bottom: number,
    left: number,
  }),
  tooltipOffsetX: number,
  tooltipOffsetY: number,
  showPieLabel: bool,
  shouldRenderBlankPie: bool,
  TooltipChildren: func,
  setPieLabel: func,
  pieStyle: object,
  pieLabelStyle: object,
  tooltipContainerStyle: object,
}

export { PieChartBase }
