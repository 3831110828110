import React from 'react'

import { styled, s } from '@horizon/styled'
import { OverlayProvider } from '@react-aria/overlays'

const StyledOverlayProvider = styled(OverlayProvider)(s('h-full'))

function AccessibilityProvider(props) {
  return <StyledOverlayProvider {...props} />
}

export { AccessibilityProvider }
