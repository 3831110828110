import React from 'react'
import { useRadio } from '@react-aria/radio'
import { useRadioContext } from './RadioButtonField'
import { styled, s } from '@horizon/styled'

const Label = styled.label(
  s('flex w-full text-center justify-center items-center border-1'),
  s('border-solid border-grey-300 px-4 text-base font-normal text-grey-800', {
    '&:first-of-type': s('border-1', { borderRadius: '6px 0 0 6px' }),
    '&:last-child': { borderRadius: '0 0 6px 0' },
    cursor: 'pointer',
    minHeight: 52,
    borderLeftWidth: 0,
  }),
  ({ isSelected }) => isSelected && s('font-semibold bg-green-400'),
  ({ readOnly }) => readOnly && { pointerEvents: 'none' },
  ({ disabled }) => disabled && s('text-grey-400', { pointerEvents: 'none' })
)

const Input = styled.input(
  s('w-0 fixed', {
    opacity: 0,
  })
)

const RadioButton = (props) => {
  const { children, onClick, readOnly, disabled, ...otherProps } = props
  const state = useRadioContext()
  const ref = React.useRef(null)
  const { inputProps } = useRadio(props, state, ref)

  return (
    <Label
      isSelected={inputProps.checked}
      onClick={onClick}
      readOnly={readOnly}
      disabled={disabled}
      {...otherProps}
    >
      <Input ref={ref} {...inputProps} />
      {children}
    </Label>
  )
}

export default RadioButton
