import React from 'react'
import { prop, includes } from '@solta/ramda-extra'
import { useSelectState } from '@react-stately/select'
import { useSelect, HiddenSelect } from '@react-aria/select'
import { useButton } from '@react-aria/button'
import { styled, s } from '@horizon/styled/v2'
import { ReactComponent as ArrowIcon } from '@horizon/components/src/assets/images/dropdownArrow.svg'

import { ListBoxPopup } from './ListBoxPopup'
import { FormMessage } from '../../FormInput'

const Container = styled('div', {
  shouldForwardProp: (prop) => !includes(prop, ['onSelectionChange', 'selectedKey']),
})(s('relative w-full'))

const Toggler = styled.button(
  s(
    'border-solid border-1 border-grey-400 bg-transparent text-grey-800 text-base flex items-center justify-between px-4 w-full rounded-lg',
    {
      minHeight: 52,
      '&:hover': s('border-primary'),
      ':focus-within': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
    }
  ),
  ({ hasError }) =>
    hasError &&
    s('border-error-400 text-grey-500', {
      '&:hover, &:focus': s('border-1 border-error-400 text-grey-500'),
    }),
  ({ disabled }) =>
    disabled &&
    s('bg-grey-200 text-grey-400 border-1 border-transparent', {
      pointerEvents: 'none',
    }),
  ({ readonly }) =>
    readonly && s('bg-grey-100 text-grey-800 border-1 border-transparent'),
  prop('togglerStyles')
)

const LabelContainer = styled.div(
  s('flex justify-between mb-1'),
  prop('labelContainerStyles')
)

const Label = styled.label(s('text-base font-normal text-grey-800'))
const Placeholder = styled.span(s('text-base font-normal text-grey-500'))
const DisplayedOption = styled.span(s('text-grey-800'))

// eslint-disable-next-line complexity
function Select({
  disabled,
  readonly,
  error,
  hasError,
  togglerStyles,
  selectMenuStyles,
  labelContainerStyles,
  testId,
  menuId,
  ...props
}) {
  const state = useSelectState(props)

  const ref = React.useRef()
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref
  )

  const { buttonProps } = useButton(triggerProps, ref)

  const { label, name, placeholder = 'Please select' } = props

  return (
    <Container {...props}>
      {label && (
        <LabelContainer labelContainerStyles={labelContainerStyles}>
          <Label {...labelProps}>{label}</Label>
        </LabelContainer>
      )}
      <HiddenSelect state={state} triggerRef={ref} label={label} name={name} />

      <Toggler
        {...buttonProps}
        ref={ref}
        togglerStyles={togglerStyles}
        hasError={hasError}
        disabled={disabled || readonly}
        readonly={readonly}
        data-test-id={testId}
      >
        {state.selectedItem ? (
          <DisplayedOption {...valueProps}>
            {state.selectedItem.rendered}
          </DisplayedOption>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        <ArrowIcon aria-hidden="true" width={8} />
      </Toggler>

      {state.isOpen && (
        <ListBoxPopup
          {...menuProps}
          selectMenuStyles={selectMenuStyles}
          state={state}
          menuId={menuId}
        />
      )}
      <FormMessage message={error} visible={Boolean(hasError)} id={name} />
    </Container>
  )
}

export { Select }
