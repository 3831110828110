import React from 'react'
import { useMenu } from '@react-aria/menu'
import { useTreeState } from '@react-stately/tree'
import { mergeProps } from '@react-aria/utils'
import { FocusScope } from '@react-aria/focus'
import { useOverlay, DismissButton } from '@react-aria/overlays'

import { s, styled } from '@horizon/styled'

import { MenuItem as DefaultMenuItem } from './MenuItem'

const MenuList = styled.ul(
  s(
    'absolute py-3 px-0 mt-1 bg-white shadow-xl rounded-lg pin-r-0 flex flex-column items-center z-3 w-full'
  ),
  s('border-solid border-1 border-grey-200 max-h-24'),
  { overflowY: 'auto', gap: 4 }
)

function MenuPopup(props) {
  const {
    onClose,
    domProps,
    selectionMode = 'none',
    MenuItem = DefaultMenuItem,
  } = props
  const treeState = useTreeState({
    ...props,
    selectionMode,
  })
  const ref = React.useRef(null)
  const { menuProps } = useMenu(props, treeState, ref)

  const overlayRef = React.useRef(null)
  const { overlayProps } = useOverlay(
    {
      onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  )

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={onClose} />
        <MenuList {...mergeProps(menuProps, domProps)} ref={ref}>
          {[...treeState.collection].map((item) => (
            <MenuItem
              key={item.key}
              item={item}
              state={treeState}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </MenuList>
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  )
}

export { MenuPopup }
