/* eslint-disable complexity */
import React from 'react'
import { styled, s, smaller } from '@horizon/styled'
import { Link } from 'react-router-dom'

import neoLogo from '../../assets/images/neo-logo.png'
import errorPageIllustrationSrc from '../../assets/images/error-page-illustration.svg'
import { useSelector } from 'react-redux'
import { includes } from '@solta/ramda-extra'
import { selectProfileRoles } from '@horizon/redux.profile'
import { USER_ROLES } from '@vega/constants'

const Root = styled.div(
  s('relative flex flex-column items-center w-full h-full bg-green-50')
)
const Container = styled.div(
  s('relative h-full w-full px-32'),
  { maxWidth: 1440 },
  smaller('lg')({ maxWidth: 1024 }),
  smaller('md')(s('px-24')),
  smaller('sm')(s('px-12'))
)
const Img = styled.img(s('w-8'))
const ImageContainer = styled(Link)(s('absolute pin-t-8 pin-l-12 no-decoration'), {
  width: 'min-content',
})
const Content = styled.div(s('mt-48'), { maxWidth: 'max-content' })
const Title = styled.h1(
  s('mb-3 text-green-800 font-extrabold', { fontSize: 64, letterSpacing: '0.01em' })
)
const Subtitle = styled.h2(
  s('text-2xl font-normal mb-10 text-grey-700', { lineHeight: '43px' })
)
const Label = styled.span(s('text-xl text-grey-500'))
const Links = styled.dd(s('flex mx-0 my-4'))
const CustomLink = styled.a(
  s('mr-12 text-base text-green-700', { textDecoration: 'none' })
)
const Illustration = styled(Img)(s('absolute pin-br-0 z-0'), {
  width: '60%',
  maxWidth: 675,
})
const ErrorMessage = styled.p(s('m-0 p-0'))

const {
  BANKING_CLIENT,
  BANKING_ADMIN,
  CREDIT_ASSESSOR,
  LEAD_ASSESSOR,
  BANKING_PORTFOLIO_ADMIN,
} = USER_ROLES

const ErrorPage = ({ homeRoute, ...otherProps }) => {
  const bankPortalUrl = process.env.REACT_APP_BANKING_WEB_URI
  const loanOriginationPortalUrl = process.env.REACT_APP_LOAN_ORIGINATION_WEB_URI
  const bankingPortfolioPortalUrl = process.env.REACT_APP_BANKING_PORTFOLIO_WEB_URI

  const roles = useSelector(selectProfileRoles)
  const isABankerRole =
    includes(BANKING_CLIENT, roles) || includes(BANKING_ADMIN, roles)

  const isALoanOriginationRole =
    includes(CREDIT_ASSESSOR, roles) || includes(LEAD_ASSESSOR, roles)

  const isAPortfolioRole = includes(BANKING_PORTFOLIO_ADMIN, roles)

  return (
    <Root {...otherProps}>
      <ImageContainer to={homeRoute}>
        <Img src={neoLogo} />
      </ImageContainer>

      <Container>
        <Content>
          <Title>OOPS!</Title>
          <Subtitle>
            <ErrorMessage>Isn&apos;t this embarrassing?</ErrorMessage>
            <ErrorMessage>
              We can&apos;t seem to find what you&apos;re looking for.
            </ErrorMessage>
          </Subtitle>

          <Label>Here are some helpful links:</Label>

          <Links>
            {isABankerRole && <CustomLink href={bankPortalUrl}>Vega Bank</CustomLink>}
            {isALoanOriginationRole && (
              <CustomLink href={`${loanOriginationPortalUrl}`} style={s('mr-6')}>
                Credit Assessor Portal
              </CustomLink>
            )}
            {isAPortfolioRole && (
              <CustomLink href={`${bankingPortfolioPortalUrl}`}>
                Banking Portfolio Portal
              </CustomLink>
            )}
          </Links>
        </Content>
      </Container>

      <Illustration src={errorPageIllustrationSrc} />
    </Root>
  )
}

export default ErrorPage
