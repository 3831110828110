/* eslint-disable complexity */
import React, { useState } from 'react'
import { ReactComponent as XCircleIcon } from '@horizon/components/src/assets/images/x-circle.svg'
import { useComboBox } from '@react-aria/combobox'
import { Item } from '@react-stately/collections'
import { useComboBoxState } from '@react-stately/combobox'
import {
  prop,
  isNotNilOrEmpty,
  propEq,
  find,
  defaultTo,
  mapObjIndexed,
  pipe,
  pick,
  assoc,
  includes,
  pluck,
  reject,
} from '@solta/ramda-extra'

import { useField } from 'formik'

import { useSearchApplicant } from './helpers/useSearchApplicant'
import { styled, s } from '@horizon/styled'

import { ListBoxPopup } from './ListBoxPopup'
import { FormMessage } from '@horizon/components'

const Root = styled.div(
  s('inline-flex flex-column w-full', { boxSizing: 'border-box' })
)
const Label = styled.label(s('text-grey-600 font-normal text-base mb-4'))
const InputContainer = styled.div(
  s('border-1 border-solid border-grey-200 bg-transparent relative flex'),
  s('p-4 pl-1 w-full h-full'),
  {
    boxSizing: 'border-box',
    borderRadius: 6,
    borderTopRightRadius: 0,
    ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    ':hover': s('border-green-400'),
  },
  ({ isTouched }) => isTouched && s('border-green-400'),
  ({ hasError, isTouched }) =>
    hasError &&
    isTouched &&
    s('border-error-400', {
      ':hover': s('border-error-400'),
      ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    }),
  ({ isFilled }) => isFilled && s('border-grey-300'),
  ({ hasError, isFilled }) =>
    hasError &&
    isFilled &&
    s('border-error-400', {
      ':focus-within': s('border-error-400', { boxShadow: '0 0 0 1px #F53244' }),
    })
)

const Input = styled.input(
  s('h-full w-full border-0 bg-transparent'),
  s('text-grey-800 text-base font-medium'),
  {
    outline: 'none',
    '&::placeholder': s('text-grey-500'),
    marginLeft: 10,

    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  }
)

const ClearInputBtn = styled(XCircleIcon)(
  s('absolute pin-tr-0 mr-4', {
    width: 20,
    height: 20,
    top: '50%',
    transform: 'translateY(-50%)',
  })
)

const ApplicantSearchField = ({
  label,
  prefix,
  placeholder,
  testId,
  menuId,
  parentPath,
  selectedApplicants,
  ...props
}) => {
  const [
    { onChange, onBlur, value: currentValue },
    { touched, error },
    { setTouched },
  ] = useField({
    name: `${parentPath}.firstName`,
  })

  const [selectedResult, setSelectedResult] = useState(currentValue)
  const [searchQuery, setSearchQuery] = useState(currentValue ?? '')
  const [hasSearched, setHasSearched] = useState(false)

  const clearSearchFieldValue = () => {
    setSearchQuery('')
    setSelectedResult('')
    onChange({ target: { value: undefined, name: `${parentPath}.firstName` } })
  }

  const { results, applicantSearch, resetResults, isLoading } = useSearchApplicant(200)

  const updateApplicant = mapObjIndexed((value, name) => {
    onChange({ target: { value, name: `${parentPath}.${name}` } })
  })

  const resetSelectedApplicant = () =>
    updateApplicant({
      id: undefined,
      firstName: undefined,
      email: '',
      isNew: undefined,
    })

  const handleInputChange = (value) => {
    setSelectedResult(undefined)
    setHasSearched(true)

    if (isNotNilOrEmpty(value)) {
      applicantSearch(value)
      resetSelectedApplicant()
    }
    setSearchQuery(value)
  }

  const handleSelectionChange = (id) => {
    setHasSearched(false)
    setTouched(true)
    const selectedResult = find(propEq('id', id), results)

    setSelectedResult(selectedResult)

    const pickedApplicant = pipe(
      defaultTo({}),
      pick(['id', 'firstName', 'email']),
      assoc('isNew', false)
    )(selectedResult)

    updateApplicant(pickedApplicant)

    const firstName = selectedResult?.firstName ?? ''
    setSearchQuery(firstName)
  }

  const addNewApplicant = () => {
    setTouched(true)
    setHasSearched(false)
    updateApplicant({
      id: undefined,
      isNew: true,
      firstName: searchQuery,
      email: '',
    })
  }

  const handleBlur = (...args) => {
    resetResults()
    onBlur(...args)
    setTouched(true)
  }

  const getUnselectedItems = (availableApplicants, alreadySelectedApplicants) => {
    const selectedIds = pluck('id', alreadySelectedApplicants)
    const alreadySelected = ({ id }) => includes(id, selectedIds)
    return reject(alreadySelected, availableApplicants)
  }

  const noFilter = () => true
  const state = useComboBoxState({
    ...props,
    /* eslint-disable-next-line */
    children: (item) => (
      <Item key={item.id}>
        {item.firstName} {item.lastName}
      </Item>
    ),

    isOpen: !selectedResult && !isLoading && hasSearched,

    items: getUnselectedItems(results, selectedApplicants),

    inputValue: searchQuery,
    onInputChange: handleInputChange,

    selectedKey: prop('id')(selectedResult),
    onSelectionChange: handleSelectionChange,

    defaultFilter: noFilter,
    disallowEmptySelection: false,

    autoFocus: false,
  })

  const triggerRef = React.useRef()
  const inputRef = React.useRef()
  const listBoxRef = React.useRef()
  const popoverRef = React.useRef()

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      placeholder,
      label,
      inputRef,
      // We must pass in a button ref, otherwise useComboBox() will fail.
      buttonRef: triggerRef,
      listBoxRef,
      popoverRef,
      menuTrigger: 'input',
    },
    state
  )

  const onListClosed = () => {
    state.close()
    setHasSearched(false)
    setTouched(true)
    setSearchQuery('')
    updateApplicant({
      id: undefined,
      firstName: undefined,
      isNew: undefined,
      email: '',
    })
  }

  return (
    <Root {...props}>
      {label && <Label {...labelProps}>{label}</Label>}
      <InputContainer isFilled={currentValue} isTouched={touched} hasError={error}>
        <Input
          {...inputProps}
          type="search"
          ref={inputRef}
          onBlur={handleBlur}
          data-test-id={testId}
        />
        {state.isOpen && (
          <ListBoxPopup
            {...listBoxProps}
            shouldUseVirtualFocus
            listBoxRef={listBoxRef}
            popoverRef={popoverRef}
            state={state}
            menuId={menuId}
            addNewApplicant={addNewApplicant}
            onClose={onListClosed}
          />
        )}
        {isNotNilOrEmpty(inputProps?.value) && (
          <ClearInputBtn onClick={clearSearchFieldValue} />
        )}
      </InputContainer>
      <FormMessage
        message={error}
        visible={touched && error}
        id={`${parentPath}.firstName`}
      />
    </Root>
  )
}

export { ApplicantSearchField }
