import React, { useEffect, useCallback, useState } from 'react'
import { AuthSdk as Wallet } from '@midas/idkit'
import { SoltaAuth } from '@midas/idkit-ui-web'
import {
  getUserForceSignedOutFlag,
  removeUserForceSignedOutFlag,
} from '@horizon/app-access-controller'
import { helpers, AUTH, APP_URLS, APPS } from '@horizon/constants'
import {
  path,
  isNilOrEmpty,
  isNotNilOrEmpty,
  includes,
  keys,
  defaultTo,
  isNil,
} from '@solta/ramda-extra'
import { useOnClickOutside } from '@horizon/components'
import logoIcon from '@horizon/components/src/assets/images/lms_logo_horizontal.png'
import * as constants from '@vega/constants'
import { fetchMyProfile, selectProfile } from '@horizon/redux.profile'

// this needs to be imported for window.xprops to be defined
// eslint-disable-next-line no-unused-vars
import { AuthComponent } from '@horizon/services'
import { s } from '@horizon/styled/v2'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'

import ForceSignedOutModal from './ForceSignedOutModal'

import { isDev } from 'utils/isDev'

const { USER_ROLES } = constants
const validAppNames = keys(APPS)

const { AUTH_COOKIE_KEY, AUTH_ID_TOKEN_KEY, CREDENTIALS_COOKIE_KEY } = AUTH
const VEGA_SUBDOMAIN = process.env.REACT_APP_SUB_DOMAIN

function redirectAuthenticatedUser(profile) {
  function redirectToMfaPage() {
    const redirectUrl = `${APP_URLS.AUTH}setup-totp`
    window.location.replace(redirectUrl)
  }

  function shouldRedirectByAppName(redirectAppName) {
    return includes(redirectAppName, validAppNames)
  }

  function redirectByAppName(appName) {
    const redirectUrl = APP_URLS[appName]
    window.location.replace(redirectUrl)
  }

  function redirectByDefaultRole(role) {
    const redirectUrl = helpers.lookUpAppRouteByRole(role)
    window.location.replace(redirectUrl)
  }

  const urlParams = new URLSearchParams(window.location.search)
  const redirectApp = urlParams.get('redirectApp')
  const isFirstSignIn = urlParams.get('firstsignin')

  if (isFirstSignIn) {
    redirectToMfaPage()
    return null
  }

  if (shouldRedirectByAppName(redirectApp)) {
    redirectByAppName(redirectApp)
    return null
  }

  redirectByDefaultRole(profile?.roles?.[0] || USER_ROLES.BROKER)
  return null
}

// eslint-disable-next-line complexity
function SignIn() {
  const profile = useSelector(selectProfile)
  const authSessionCookieExists = isNotNilOrEmpty(
    JSON.parse(defaultTo(null, Cookies.get(AUTH_COOKIE_KEY)))
  )
  const dispatch = useDispatch()
  const [showForcedOutModal, setShowForcedOutModal] = useState(false)
  const userSignedOutForcefully = getUserForceSignedOutFlag()

  const userAcknowledgesForceSignout = useCallback(() => {
    setShowForcedOutModal(false)
    if (isNotNilOrEmpty(userSignedOutForcefully)) removeUserForceSignedOutFlag()
  }, [userSignedOutForcefully])
  const forceSignoutModalRef = React.useRef()
  useOnClickOutside(forceSignoutModalRef, userAcknowledgesForceSignout)

  const onCredentialsUpdate = useCallback((data) => {
    if (isNilOrEmpty(data)) {
      Cookies.set(CREDENTIALS_COOKIE_KEY, null, {
        domain: VEGA_SUBDOMAIN,
        secure: !isDev(),
        sameSite: 'strict',
      })

      Cookies.set(AUTH_ID_TOKEN_KEY, null, {
        domain: VEGA_SUBDOMAIN,
        secure: !isDev(),
        sameSite: 'strict',
      })

      if (window?.xprops?.onAuthSessionRefreshed) {
        window.xprops.onAuthSessionRefreshed()
      }
      return
    }

    Cookies.set(CREDENTIALS_COOKIE_KEY, JSON.stringify(data), {
      domain: VEGA_SUBDOMAIN,
      secure: !isDev(),
      sameSite: 'strict',
    })
  }, [])

  const onAuthSessionUpdate = useCallback(
    (data) => {
      if (isNilOrEmpty(data)) {
        Cookies.set(AUTH_COOKIE_KEY, null, {
          domain: VEGA_SUBDOMAIN,
          secure: !isDev(),
          sameSite: 'strict',
        })

        Cookies.set(AUTH_ID_TOKEN_KEY, null, {
          domain: VEGA_SUBDOMAIN,
          secure: !isDev(),
          sameSite: 'strict',
        })

        return
      }

      const authSession = {
        accessToken: path(['accessToken', 'jwtToken'], data),
        refreshToken: path(['refreshToken', 'token'], data),
        expiryDate: path(['accessToken', 'payload', 'exp'], data),
      }

      Cookies.set(AUTH_COOKIE_KEY, JSON.stringify(authSession), {
        domain: VEGA_SUBDOMAIN,
        secure: !isDev(),
        sameSite: 'strict',
      })

      const idToken = { idToken: path(['idToken', 'jwtToken'], data) }

      Cookies.set(AUTH_ID_TOKEN_KEY, JSON.stringify(idToken), {
        domain: VEGA_SUBDOMAIN,
        secure: !isDev(),
        sameSite: 'strict',
      })

      Wallet.getCurrentCredentials().then(onCredentialsUpdate)

      dispatch(fetchMyProfile())
    },
    [dispatch, onCredentialsUpdate]
  )

  useEffect(() => {
    Wallet.getCurrentSession().then(onAuthSessionUpdate)

    return Wallet.on('tokenChange', onAuthSessionUpdate)
  }, [onAuthSessionUpdate, onCredentialsUpdate])

  useEffect(() => {
    if (userSignedOutForcefully === 'true') {
      setShowForcedOutModal(true)
      window.addEventListener('beforeunload', () => userAcknowledgesForceSignout())
      const pageRefreshed = window.performance
        .getEntriesByType('navigation')
        ?.map((nav) => nav.type)
        ?.includes('reload')

      if (pageRefreshed) userAcknowledgesForceSignout()
    }
  }, [userAcknowledgesForceSignout, userSignedOutForcefully])

  const isAuthenticatedUser =
    isNotNilOrEmpty(profile?.authId) && authSessionCookieExists
  if (isAuthenticatedUser) redirectAuthenticatedUser(profile)

  if (showForcedOutModal)
    return (
      <div>
        <ForceSignedOutModal
          ref={forceSignoutModalRef}
          isOpen={showForcedOutModal}
          onAcknowledgement={userAcknowledgesForceSignout}
        />
      </div>
    )

  const customStyles = {
    containerStyles: s('bg-primary-flat-white'),
    headingStyles: s('text-gray-800', { fontFamily: 'sohne' }),
    innerLinkStyles: s('text-primary'),
    inputStyles: {
      '&:hover:not([disabled])': s('border-1 border-primary', { outline: 'none' }),
      '&:focus': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
      '&:active': s('border-1 border-primary', { outline: 'none' }),
    },
    helperTextStyles: s('text-grey-600'),
    buttonStyles: s('shadow-none bg-primary text-black', {
      '&:hover:not([disabled])': s('bg-secondary', {
        boxShadow:
          '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
      }),

      '&:disabled': s('bg-grey-200 text-grey-600'),
    }),
  }

  return (
    <SoltaAuth
      Logo={() => <img src={logoIcon} width="60%" />}
      customStyles={customStyles}
      allowSelfSignUp={false}
    />
  )
}

export { SignIn }
